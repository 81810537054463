import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";
import { $gb } from "stores/growthbook";

export const useGrowthbookFeature = (
  feature: string,
  defaultValue: boolean
) => {
  const [isOn, setIsOn] = useState(defaultValue);
  const gb = useStore($gb);

  useEffect(() => {
    if (!gb) return;

    setIsOn(gb.getFeatureValue(feature, defaultValue));
  }, [gb]);

  return isOn;
};
