export const getTargetArguments = (
  uberCategory?: string,
  superCategory?: string,
  mainCategory?: string,
  productGroup?: string | string[],
  contentType?: string,
  slug?: string,
  articleId?: string
) => {
  const args: {
    ubercategory?: [string];
    supercategory?: [string];
    maincategory?: [string];
    productgroup?: [string] | string[];
    contenttype?: [string];
    slug?: [string];
    articleId?: [string];
  } = {};

  if (uberCategory) args.ubercategory = [uberCategory];

  if (superCategory) args.supercategory = [superCategory];

  if (mainCategory) args.maincategory = [mainCategory];

  if (productGroup && typeof productGroup === "string")
    args.productgroup = [productGroup];
  else if (productGroup && productGroup.length > 0)
    args.productgroup = [...productGroup];

  if (contentType) args.contenttype = [contentType];

  if (slug) args.slug = [slug];

  if (articleId) args.articleId = [articleId];

  return args;
};

export const getAdUnitNameByContentType = (
  name: string,
  contentType?: string
) => {
  let parent = "productlisting";

  switch (contentType) {
    case "homepage":
      parent = "homepage";
      break;
    case "prijsdalers":
      parent = "prijsdalers";
      break;
    case "productdetail":
      parent = "productdetail";
      break;
    case "artikel":
      parent = "content";
      break;
    case "content":
      parent = "content";
      break;
    case "partnerbijdrage":
      parent = "partnerbijdrage";
      break;
    default:
      parent = "productlisting";
  }

  return `${parent}/${name}`;
};
