"use client";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../atoms/pagination";

interface PaginationBarProps {
  currentPage: number;
  renderedArticles?: number;
  totalPages?: number;
  totalArticles?: number;
}

const PaginationBar = ({
  currentPage = 1,
  renderedArticles = 20,
  totalArticles,
  totalPages: totalPagesParam,
}: PaginationBarProps) => {
  const isCurrentPageFirst = currentPage === 1;

  const totalPages =
    totalArticles !== undefined
      ? Math.ceil(totalArticles / renderedArticles)
      : totalPagesParam;

  const isCurrentPageLast = currentPage === totalPages;

  const getPageNumbers = () => {
    let isPageNumberOutOfRange = false;

    return Array.from(Array(totalPages)).map((_, index) => {
      const pageNumber = index + 1;

      const isPageNumberFirst = pageNumber === 1;
      const isPageNumberLast = pageNumber === totalPages;
      const isCurrentPageWithinTwoPageNumbers =
        Math.abs(pageNumber - currentPage) <= 1;

      if (
        isPageNumberFirst ||
        isPageNumberLast ||
        isCurrentPageWithinTwoPageNumbers
      ) {
        isPageNumberOutOfRange = false;

        return (
          <PaginationItem key={pageNumber}>
            <PaginationLink
              href={`?page=${pageNumber}`}
              isActive={currentPage === pageNumber}
            >
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        );
      }

      if (!isPageNumberOutOfRange) {
        isPageNumberOutOfRange = true;
        return <PaginationEllipsis key={pageNumber} />;
      }

      return null;
    });
  };

  return (
    <Pagination className="mb-4">
      <PaginationContent>
        {!isCurrentPageFirst && (
          <PaginationItem>
            <PaginationPrevious href={`?page=${currentPage - 1}`} />
          </PaginationItem>
        )}
        {getPageNumbers()}
        {!isCurrentPageLast && (
          <PaginationItem>
            <PaginationNext href={`?page=${currentPage + 1}`} />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  );
};

export { PaginationBar };
