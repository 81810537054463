import { useCallback, useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { Grid01, Rows01 } from "@untitled-ui/icons-react";
import type { MinimalProduct } from "api-v2/products/types";
import { ProductCard } from "../molecules/product-card-refreshed";
import {
  cn,
  getAdUnitNameByContentType,
  getFiltersFromHash,
  getProductSlug,
  listenToConsentChange,
  sanitizeFilter,
  setHashFilters,
} from "../../utils";
import { getClientProducts } from "api-v2/products";
import { getClientAds } from "api-v2/kevel";
import type { Ads } from "api-v2/kevel/types";
import { isServer } from "api/utils";
import { Ad } from "../atoms/ad";
import { Decision } from "api/ads/types";
import { NativeAd } from "../atoms/native-ad";
import { PaginationBar } from "../molecules/pagination-bar";
import { Button } from "../atoms/button";
import { Icon } from "../atoms/icon/icon";
import { ModalPrices } from "../molecules/modal-prices";
import { useGrowthbookFeature } from "../../hooks/use-growthbook-feature";

interface ListItem {
  type: "product" | "native" | "display" | "review";
  product?: MinimalProduct;
  page?: number;
  ad?: Decision;
  display?: {
    sizes: googletag.GeneralSize;
    height: number;
    targetingArguments: Record<string, string>;
    mobile?: boolean;
    desktop?: boolean;
    tablet?: boolean;
  };
}

interface ProductListProps {
  initialProducts: ListItem[];
  initialProductAmount: number;
  initialPage?: number;
  initialFilters?: Record<string, string[]>;
  initialTotalPages?: number;
  adsConfig?: {
    appName: string;
    networkCode: string;
    contentType: string;
    hideNative?: boolean;
    properties: Record<string, string>;
  };
  pathname: string;
  initialOrientation?: string;
  hideToggle?: boolean;
}

const SORT_OPTIONS = [
  "Meest bekeken",
  "Meest verkocht",
  "Reviewscore",
  "Prijs oplopend",
  "Prijs aflopend",
  "Prijsverschil",
];

const PRODUCTS_PER_PAGE = 15;

export const mergeNativeAds = (products: MinimalProduct[], ads?: Ads) => {
  const reviewTile = ads?.decisions.reviewTile || null;

  const nativeAds =
    ads?.decisions.productTile
      ?.map((ad, index) => {
        if (reviewTile && index === 1)
          return {
            ad: reviewTile[0],
          };

        return {
          ad,
        };
      })
      .filter(Boolean) || [];

  if (reviewTile && nativeAds.length <= 1)
    nativeAds[nativeAds.length === 0 ? 0 : 1] = { ad: reviewTile[0] };

  const mergedProducts: ListItem[] = [];
  let nativeIndex = 0;

  for (let i = 0; i < products.length; i++) {
    if ([1, 2, 5, 8, 9].includes(i) && nativeIndex < nativeAds.length) {
      mergedProducts.push({
        ...nativeAds[nativeIndex],
        type: "native",
      });

      nativeIndex++;
    }

    mergedProducts.push({ product: products[i], type: "product" });
  }

  return mergedProducts;
};

export const mergeDisplayAds = (products: ListItem[]) => {
  return products.flatMap((item, index) => {
    const isBillboardIndex = index === 2 || (index + 4) % 6 === 0;
    const isRectangleIndex = index === 1 || (index + 2) % 3 === 0;

    const result = [item];

    if (isRectangleIndex) {
      result.push({
        type: "display",
        display: {
          sizes: [
            [336, 280],
            [300, 250],
            [300, 50],
            [320, 50],
            [300, 100],
            [320, 100],
          ],
          height: 280,
          targetingArguments: { pos: "rectangle" },
          mobile: true,
        },
      });
    }

    if (isBillboardIndex) {
      result.push({
        type: "display",
        display: {
          sizes: [
            [728, 90],
            [728, 200],
          ],
          height: 280,
          targetingArguments: { pos: "billboard" },
          tablet: true,
        },
      });

      result.push({
        type: "display",
        display: {
          sizes: [
            [970, 250],
            [970, 90],
            [728, 90],
            [728, 200],
          ],
          height: 280,
          targetingArguments: { pos: "billboard" },
          desktop: true,
        },
      });
    }

    return result;
  });
};

const ProductList = ({
  initialProducts,
  initialProductAmount,
  initialPage = 1,
  initialTotalPages = 1,
  initialFilters = {},
  adsConfig,
  pathname,
  initialOrientation = "grid",
  hideToggle,
}: ProductListProps) => {
  const featureListingOrientationRow = useGrowthbookFeature(
    "listing-orientation-row",
    false
  );

  const [priceModalProduct, setPriceModalProduct] = useState<number | null>(
    null
  );
  const [orientation, setOrientation] = useState(initialOrientation);
  const [products, setProducts] = useState<ListItem[]>(initialProducts);
  const [productAmount, setProductAmount] =
    useState<number>(initialProductAmount);
  const [totalPages, setTotalPages] = useState<number>(initialTotalPages);
  const [page, setPage] = useState<number>(initialPage);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const hasClientRendered = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const lastFetchedPage = useRef<number | null>(null);

  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const totalProductCards = useRef((initialPage - 1) * PRODUCTS_PER_PAGE);
  const adCountDesktop = useRef(1);
  const adCountTablet = useRef(1);
  const adCountMobile = useRef(1);

  useEffect(() => {
    if (featureListingOrientationRow) setOrientation("row");
  }, [featureListingOrientationRow]);

  const fetchProducts = useCallback(
    async (pageNumber: number) => {
      if (loading || lastFetchedPage.current === pageNumber) return;

      try {
        if (hasClientRendered.current) setLoading(true);

        lastFetchedPage.current = pageNumber;

        const isMobile =
          typeof window !== "undefined"
            ? window.matchMedia("(max-width: 768px)").matches
            : false;

        const filters = getFiltersFromHash();
        const placements = [
          {
            divName: "productTile",
            adTypes: [301, 157, 484],
            count: 5,
            properties: adsConfig?.properties,
          },
        ];

        if (isMobile && pageNumber === initialPage)
          placements.push({
            divName: "reviewTile",
            adTypes: [2208],
            count: 1,
            properties: adsConfig?.properties,
          });

        const { data: ads } = await getClientAds({
          placements,
        });

        let totalCount =
          (ads.decisions.productTile?.length || 0) + PRODUCTS_PER_PAGE;
        let remainder = totalCount % 3;
        let additionalNeeded = remainder === 0 ? 0 : 3 - remainder;
        let limit = PRODUCTS_PER_PAGE + additionalNeeded;

        const skip =
          pageNumber === initialPage
            ? (pageNumber - 1) * PRODUCTS_PER_PAGE
            : totalProductCards.current;

        const { data: newProducts } = await getClientProducts({
          ...initialFilters,
          ...filters,
          skip: [skip.toString()],
          limit: [limit.toString()],
        });

        totalProductCards.current =
          totalProductCards.current + newProducts.data.length;

        let mergedProducts = mergeNativeAds(newProducts.data, ads);
        if (adsConfig) mergedProducts = mergeDisplayAds(mergedProducts);

        mergedProducts = mergedProducts.map((item) => ({
          ...item,
          page: pageNumber,
        }));

        setProducts((prev) =>
          pageNumber === 1 ? mergedProducts : [...prev, ...mergedProducts]
        );

        const lastPage = Math.min(
          Math.ceil(newProducts.meta.total / newProducts.meta.limit),
          20
        );

        setProductAmount(newProducts.meta.total);
        setTotalPages(lastPage);
        setHasMore(pageNumber < lastPage);

        setLoading(false);

        adCountDesktop.current = 1;
        adCountTablet.current = 1;
        adCountMobile.current = 1;

        hasClientRendered.current = true;
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    },
    [hasClientRendered.current]
  );

  useEffect(() => {
    const handleHashChange = async () => {
      setPage(1);
      updatePageInURL(1);

      lastFetchedPage.current = null;
      await fetchProducts(1);
    };

    const hash = getFiltersFromHash();
    if (Object.keys(hash).length === 0) {
      updatePageInURL(initialPage);
      fetchProducts(initialPage);
    }

    const handleConsentChange = async () => {
      setPage(initialPage);
      updatePageInURL(initialPage);

      lastFetchedPage.current = null;
      await fetchProducts(initialPage);
    };

    window.addEventListener("hashchange", handleHashChange);
    listenToConsentChange(handleConsentChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, [fetchProducts]);

  const updatePageInURL = (newPage: number) => {
    const params = new URLSearchParams(window.location.search);

    if (newPage > 1) {
      params.set("page", newPage.toString());
    } else {
      params.delete("page");
    }

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}${params.size > 0 ? `?${params}` : ""}${window.location.hash}`
    );
  };

  useEffect(() => {
    let debounceTimeout: NodeJS.Timeout | null = null;
    const visiblePages = new Set<number>();

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const pageNumber = Number(entry.target.getAttribute("data-page"));

          if (entry.isIntersecting && pageNumber > 0) {
            visiblePages.add(pageNumber);
          } else {
            visiblePages.delete(pageNumber);
          }

          if (visiblePages.size > 0) {
            const minPage = Math.min(...Array.from(visiblePages));
            updatePageInURL(minPage);
          }

          if (entry.isIntersecting) {
            if (entry.target === sentinelRef.current && !loading && hasMore) {
              if (debounceTimeout) clearTimeout(debounceTimeout);
              debounceTimeout = setTimeout(() => {
                setPage(page + 1);
                fetchProducts(page + 1);
              }, 300);
            }
          }
        });
      },
      { rootMargin: "100px", threshold: 0.1 }
    );

    if (containerRef.current) {
      const productElements =
        containerRef.current.querySelectorAll("[data-page]");
      productElements.forEach((el) => observer.observe(el));
    }

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
      observer.disconnect();
    };
  }, [products, fetchProducts, hasMore]);

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const currentFilter = getFiltersFromHash();

    setHashFilters({ ...currentFilter, sorteer: [value] });
  };

  const handlePriceModal = (kieskeurigId: number) => {
    setPriceModalProduct(kieskeurigId);
  };

  const getSortValue = () => {
    if (isServer()) return initialFilters.sorteer?.[0] || "meest_bekeken";

    const currentFilter = getFiltersFromHash();
    return (
      currentFilter.sorteer?.[0] ||
      initialFilters.sorteer?.[0] ||
      "meest_bekeken"
    );
  };

  const renderCard = (item: ListItem) => {
    switch (item.type) {
      case "native": {
        const { ad } = item as { ad: Decision };
        return (
          <NativeAd
            key={`${item.page}_${ad.adId}`}
            ad={ad}
            orientation={orientation}
          />
        );
      }
      case "display": {
        const { display } = item as {
          display: NonNullable<ListItem["display"]>;
        };

        if (!adsConfig) return;

        const getAdCount = () => {
          if (display.desktop) {
            return adCountDesktop.current++;
          } else if (display.tablet) {
            return adCountTablet.current++;
          } else if (display.mobile) {
            return adCountMobile.current++;
          }
        };

        const count = getAdCount();

        return (
          <Ad
            networkCode={adsConfig.networkCode}
            adUnit={adsConfig.appName}
            name={getAdUnitNameByContentType(
              `mid${count}`,
              adsConfig.contentType
            )}
            {...display}
            className="col-span-full"
            useKevelFallback
          />
        );
      }
      default: {
        const { product } = item as { product: MinimalProduct };

        return (
          <ProductCard
            key={`${item.page}_${product.kieskeurigId}`}
            kieskeurigId={product.kieskeurigId}
            title={product.title}
            thumbnail={product.thumbnail}
            specificationSpotlight={product.specificationSpotlight}
            reviewScore={product.reviewScore}
            brand={product.brand}
            categorySlug={product.categorySlug}
            reviewCount={product.reviewCount}
            certificates={product.certificates}
            lowestPrices={product.lowestPrices!}
            link={getProductSlug(product.categorySlug, product.slug)}
            lowestPriceUntilNow={product.lowestPriceUntilNow}
            discount={product.discount}
            priceAmount={product.priceAmount}
            row={orientation === "row"}
            onClickout={handlePriceModal}
            data-page={item.page}
          />
        );
      }
    }
  };

  const handleFilterToggle = () => {
    window.dispatchEvent(
      new CustomEvent("product-filters-toggle", { detail: [] })
    );
  };

  const handleOrientationChange = (newOrientation: string) => {
    const currentOrientationCookie = JSON.parse(
      Cookies.get("listing-orientation") || "{}"
    ) as Record<string, string>;

    Cookies.set(
      "listing-orientation",
      JSON.stringify({
        ...currentOrientationCookie,
        [pathname]: newOrientation,
      })
    );

    setOrientation(newOrientation);
  };

  return (
    <>
      <div>
        {initialPage !== 1 && page !== 1 && totalPages > 1 && (
          <PaginationBar currentPage={initialPage} totalPages={totalPages} />
        )}

        <div className="flex justify-between items-center pb-3">
          <Button className={cn("lg:hidden")} onClick={handleFilterToggle}>
            Filteren <Icon icon="filter" size={12} />
          </Button>

          <span className="text-xs font-bold hidden md:block">
            {productAmount} resultaten
          </span>

          <div className="flex items-center">
            <label className="flex items-center text-sm mr-4">
              <span className="hidden md:block">Sorteer op:</span>
              <select
                className="flex-1 h-6 bg-white text-sm rounded px-2 cursor-pointer font-bold "
                aria-label="Sorteer op"
                onChange={handleSortChange}
                value={getSortValue()}
              >
                {SORT_OPTIONS.map((option) => (
                  <option key={option} value={sanitizeFilter(option)}>
                    {option}
                  </option>
                ))}
              </select>
            </label>

            <button
              type="button"
              onClick={() => handleOrientationChange("grid")}
              className={cn("toggle--grid p-1 text-border", {
                "text-foreground": orientation === "grid",
                hidden: hideToggle,
              })}
            >
              <Grid01 width={24} height={24} className="toggle--grid" />
              <span className="sr-only">Bekijk als grid</span>
            </button>

            <button
              type="button"
              onClick={() => handleOrientationChange("row")}
              className={cn("toggle--row p-1 text-border", {
                "text-foreground": orientation === "row",
                hidden: hideToggle,
              })}
            >
              <Rows01 width={24} height={24} className="toggle--row" />
              <span className="sr-only">Bekijk als rij</span>
            </button>
          </div>
        </div>

        <div
          ref={containerRef}
          className={cn("flex flex-col gap-3 md:grid md:grid-cols-3", {
            "md:flex listing--row": orientation === "row",
            "listing--grid": orientation === "grid",
            "opacity-50": loading,
          })}
        >
          {products.map(renderCard)}
        </div>

        <div ref={sentinelRef} className="h-10" />
      </div>
      <ModalPrices
        productId={priceModalProduct}
        open={priceModalProduct !== null}
        onClose={() => setPriceModalProduct(null)}
      />
    </>
  );
};

export { ProductList };
